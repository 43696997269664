<template>
    <div class="UserCcView">
        <loading-component></loading-component>
        <nav-bar-component :navbar="nav_bar" :title_name="my_nav_bar_name" @show_pp="show_popup = true"></nav-bar-component>
        <van-popup
            v-model="show_popup"
            position="top"
            round
            :close-on-click-overlay="false"
            closeable   
        >
            <van-form :key="form_key">
                <div class="search_title">
                    <van-tag round type="success" size="large">条件搜索区</van-tag>
                </div>
                <select-component
                    name="examine_type"
                    lable="申请类型"
                    :farr="this.$store.state.examine_type"
                    @clk="confirm_examine_type"
                ></select-component>
                <van-field
                    v-model="cond.name"
                    name="name"
                    label="单号"
                    placeholder="单号"
                />
                <select-component
                    name="staff_id"
                    lable="申请人"
                    :farr="all_staff"
                    @clk="confirm_staff"
                ></select-component>
                <div class="search_button">
                    <van-button round block type="info" icon="search" @click="search">搜 索</van-button>
                </div>
                <div class="search_button">
                    <van-button round block type="warning" @click="reset">重 置</van-button>
                </div>
                <div class="search_button">
                    <van-button round block type="default" @click="show_popup = false">取 消</van-button>
                </div>
            </van-form>
        </van-popup>
        <van-list
            v-model="list_loading"
            :finished="finished"
            finished-text="我是有底线的"
            @load="onLoad"
        >
            <van-cell-group inset v-for="v in list" :key="v.name" @click="show_details(v)">
                <van-cell>
                    <template #title>
                        <span v-if="v.examine_type === 10" class="title_span">业务付款</span>
                        <span v-else-if="v.examine_type === 14" class="title_span">{{v.title.split('|')[0]}}</span>
                        <span v-else-if="v.examine_type === 15" class="title_span">{{v.title | hezuoqiye_title}}</span>
                        <span v-else class="title_span">{{v.title}}</span>
                    </template>
                </van-cell>
                <van-cell class="note_cell" title="状态" :border="false">
                    <template #right-icon>
                        <van-tag plain color="#ADADAD" v-if="v.state===0">待提交</van-tag>
                        <van-tag plain type="primary" v-if="v.state===1">审批中</van-tag>
                        <van-tag plain type="success" v-if="v.state===2">已通过</van-tag>
                        <van-tag plain type="danger" v-if="v.state===3">已驳回</van-tag>
                        <span>&nbsp;</span>
                        <van-tag round color="#ADADAD" v-if="v.urgent_state===0">一般</van-tag>
                        <van-tag round type="warning" v-if="v.urgent_state===1">较急</van-tag>
                        <van-tag round type="danger" v-if="v.urgent_state===2">特急</van-tag>
                    </template>
                </van-cell>
                <van-cell class="note_cell" title="申请类型" :border="false">
                    <template #right-icon>
                        <span style="color: #B15BFF;">{{v.examine_type_name}}</span>
                    </template>
                </van-cell>
                <van-cell class="note_cell" title="单号" :value="v.name" :border="false" />
                <van-cell v-if="v.examine_type === 10" class="note_cell" title="付款方" :border="false">
                    <template #right-icon>
                        <span style="color: #CE0000;">{{v.title.split('|')[0]}}</span>
                    </template>
                </van-cell>
                <van-cell v-if="v.examine_type === 10" class="note_cell" title="收款方" :border="false">
                    <template #right-icon>
                        <span style="color: #00BB00;">{{v.title.split('|')[1]}}</span>
                    </template>
                </van-cell>
                <van-cell v-if="v.examine_type === 10" class="note_cell" title="最迟付款日期" :border="false">
                    <template #right-icon>
                        <span style="color: #000000;">{{v.title.split('|')[2]}}</span>
                    </template>
                </van-cell>
                <van-cell v-if="v.examine_type === 14" class="note_cell" title="甲方" :border="false">
                    <template #right-icon>
                        <span style="color: #CE0000;">{{v.title.split('|')[1]}}</span>
                    </template>
                </van-cell>
                <van-cell v-if="v.examine_type === 14" class="note_cell" title="乙方" :border="false">
                    <template #right-icon>
                        <span style="color: #00BB00;">{{v.title.split('|')[2]}}</span>
                    </template>
                </van-cell>
                <van-cell v-if="(v.examine_type === 14) && (v.title.split('|')[3])" class="note_cell" title="丙方" :border="false">
                    <template #right-icon>
                        <span style="color: #2828FF;">{{v.title.split('|')[3]}}</span>
                    </template>
                </van-cell>
                <van-cell v-if="(v.examine_type === 14) && (v.title.split('|')[4])" class="note_cell" title="丁方" :border="false">
                    <template #right-icon>
                        <span style="color: #2894FF;">{{v.title.split('|')[4]}}</span>
                    </template>
                </van-cell>
                <van-cell v-if="v.examine_type === 15" class="note_cell" title="我方" :border="false">
                    <template #right-icon>
                        <span style="color: #2828FF;">{{v.title.split('|')[0]}}</span>
                    </template>
                </van-cell>
                <van-cell v-if="v.examine_type === 15" class="note_cell" title="合作方" :border="false">
                    <template #right-icon>
                        <span style="color: #FF8000;">{{v.title.split('|')[1]}}</span>
                    </template>
                </van-cell>
                <van-cell
                    v-if="(v.examine_type === 0) || (v.examine_type === 1) || (v.examine_type === 2) || (v.examine_type === 6) || (v.examine_type === 7) || (v.examine_type === 10) || (v.examine_type === 16)"
                    class="note_cell"
                    title="金额"
                    :value="v | diy_money"
                    :border="false"
                />
                <van-cell class="note_cell" title="申请日期" :value="v.create_time" :border="false" />
                <van-cell class="note_cell" title="当前审批人" :value="v.current_examine_staff_name" :border="false" />
            </van-cell-group>
        </van-list>
    </div>
</template>

<script>
import LoadingComponent from '@/components/common/LoadingComponent.vue'
import NavBarComponent from '@/components/common/NavBarComponent.vue'
import SelectComponent from '@/components/common/SelectComponent.vue'

import { my_cc_request } from '@/network/user.js'
import { staff_list_request } from '@/network/list.js'
import { thousandBitSeparator,fomatFloat} from '@/assets/js/common.js'

export default {
    name:'UserCcView',
    data(){
        return {
            nav_bar: {
                left: true,
                right: {
                    type: 'text',
                    name: '搜索'
                },
            },
            my_nav_bar_name: '我的审批',
            list: [],
            total: 0,
            list_loading: false,
            finished: false,
            show_popup: false,
            form_key: Date.now(),
            cond: {
                examine_type: '',
                name: '',
                staff_id: '',
                page: 1
            },
            all_staff: []
        }
    },
    computed:{},
    methods:{
        get_my_cc() {
            this.list = []
            this.finished = false
            this.$store.commit('true_loading')
            my_cc_request(this.cond)
                .then((s) => {
                    if (s.status === 0) {
                        this.list = s.result.data
                        this.total =s.reuult.total
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    // this.$toast.fail(err);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        confirm_examine_type(e) {
            this.cond.examine_type = e.id
        },
        confirm_staff(e) {
            this.cond.staff_id = e.id
        },
        search() {
            this.cond.page = 1
            this.get_my_cc()
            this.show_popup = false
        },
        reset() {
            this.cond = {
                examine_type: '',
                name: '',
                staff_id: '',
                page: 1
            }
            this.form_key = Date.now()
        },
        show_details(v) {
            if((v.examine_type===0) && (this.$_has('采购申请详情'))) {
                this.$router.push({
                    name: 'PurchaseDetails',
                    query: {
                        id: v.id
                    }
                })
            } else if((v.examine_type===1) && (this.$_has('招待申请详情'))) {
                this.$router.push({
                    name: 'EntertainDetails',
                    query: {
                        id: v.id
                    }
                })
            } else if((v.examine_type===6) && (this.$_has('差旅申请详情'))) {
                this.$router.push({
                    name: 'TravelDetails',
                    query: {
                        id: v.id
                    }
                })
            } else if((v.examine_type===2) && (this.$_has('备用金申请详情'))) {
                this.$router.push({
                    name: 'PettyDetails',
                    query: {
                        id: v.id
                    }
                })
            } else if((v.examine_type===7) && (this.$_has('报销申请详情'))) {
                this.$router.push({
                    name: 'ReimbursementDetails',
                    query: {
                        id: v.id
                    }
                })
            } else if((v.examine_type===10) && (this.$_has('业务付款申请详情'))) {
                this.$router.push({
                    name: 'BusinessPaymentDetails',
                    query: {
                        id: v.id
                    }
                })
            } else if((v.examine_type===8) && (this.$_has('休假申请详情'))) {
                this.$router.push({
                    name: 'VacationDetails',
                    query: {
                        id: v.id
                    }
                })
            } else if((v.examine_type===11) && (this.$_has('外出申请详情'))) {
                this.$router.push({
                    name: 'GoOutDetails',
                    query: {
                        id: v.id
                    }
                })
            } else if((v.examine_type===12) && (this.$_has('加班申请详情'))) {
                this.$router.push({
                    name: 'OverTimeDetails',
                    query: {
                        id: v.id
                    }
                })
            } else if((v.examine_type===13) && (this.$_has('补签申请详情'))) {
                this.$router.push({
                    name: 'SupplementClockInDetails',
                    query: {
                        id: v.id
                    }
                })
            } else if((v.examine_type===14) && (this.$_has('合同详情'))) {
                this.$router.push({
                    name: 'ContractDetails',
                    query: {
                        id: v.id
                    }
                })
            } else if((v.examine_type===15) && (this.$_has('合作企业申请详情'))) {
                this.$router.push({
                    name: 'CooperativeEnterpriseDetails',
                    query: {
                        id: v.id
                    }
                })
            } else if((v.examine_type===17) && (this.$_has('印章使用申请详情'))) {
                this.$router.push({
                    name: 'CurrencyDetails',
                    query: {
                        id: v.id
                    }
                })
            } else if((v.examine_type===19) && (this.$_has('公务车使用申请详情'))) {
                this.$router.push({
                    name: 'CarUseDetails',
                    query: {
                        id: v.id
                    }
                })
            }
        },
        onLoad() {
            setTimeout(() => { 
                this.cond.page++
                my_cc_request(this.cond)
                    .then((s) => {
                        if (s.status === 0) {
                            this.total = s.result.total
                            s.result.data.forEach(v => {
                                this.list.push(v)
                            });
                        } else {
                            this.$toast.fail(s.msg);
                        }
                    })
                    .catch((err) => {
                        this.$toast.fail(s.msg);
                    })
                    .finally(() => {
                        // 加载状态结束
                        this.list_loading = false;

                        // 数据全部加载完成
                        if (this.list.length >= this.total) {
                            this.finished = true;
                        }
                    })
            }, 500);
        },
        get_all_staff() {
            this.$store.commit('true_loading')
            staff_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.all_staff = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
    },
    filters:{
        diy_money(v) {
            if(v['examine_type'] === 10) {
                return v['money']
            } else {
                return 'RMB：'+thousandBitSeparator(fomatFloat(v['money']))
            }
        },
        hezuoqiye_title(v) {
            const arr = v.split("|")
            let title = ''
            if(arr[4]*1 === 0) {
                title = '客户'
            } else if(arr[4]*1 === 1) {
                title = '供应商'
            } else if(arr[4]*1 === 2) {
                title = '我方企业'
            } else if(arr[4]*1 === 3) {
                title = '客户+供应商'
            }
            return '新增' +  title
        }
    },
    props:{},
    created(){
        this.get_my_cc()
        this.get_all_staff()
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        LoadingComponent,
        NavBarComponent,
        SelectComponent
    },
    watch:{}
}
</script>

<style>
.UserCcView .van-cell-group {
    margin-bottom: 0.2rem;
}
.UserCcView .title_span {
    font-weight: bold;
    color: #0080FF;
}
.UserCcView .note_cell {
    padding: 0.1rem 0.42667rem
}
.UserCcView .search_title {
    text-align: center;
}
.UserCcView .search_button {
    margin: 0.5rem;
}
.UserCcView .insert {
    text-align: center;
    /* position: absolute; */
        transform: translateY(-40%);
}
</style>